import * as React from "react"

import * as style from "./Information.module.css"

const DATA = [
	{
		label: '社名',
		value: '日影商事株式会社'
	},
	{
		label: '所在地',
		value: '〒111-0053 東京都台東区浅草橋5丁目19-10-605'
	},
	{
		label: '資本金',
		value: '500万円'
	},
	{
		label: '従業員数',
		value: '2名'
	},
	{
		label: '設立',
		value: '2022年4月22日'
	},
	{
		label: '主な事業',
		value: 'ゲームセンター等の娯楽施設の経営, ソフトウェアの企画、開発、運用、サポート及びコンサルティング'
	},
	{
		label: '代表',
		value: '代表取締役社長 森 章人'
	}
]

export default () => {
	return (
		<section className={style.block}>
			<h2 className={style.heading}>会社概要</h2>
			<dl>
				{DATA.map(v => (
					<div className={style.line}>
						<dt className={style.label}>{v.label}</dt>
						<dd className={style.value}>{v.value}</dd>
					</div>
				))}
			</dl>
		</section>
	)
}