import * as React from "react"

import * as style from "./Footer.module.css"

export default () => {
	return (
		<footer className={style.block}>
			<p>&copy; 日影商事株式会社</p>
		</footer>
	)
}