import * as React from "react"
import "modern-css-reset"

import Head from "../components/Head";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Information from "../components/Information";
import Footer from "../components/Footer";

const IndexPage = () => {
	return (
		<div>
			<Head />
			<Header />
			<Hero />
			<Information />
			<Footer />
		</div>
	)
}

export default IndexPage
