import * as React from "react"
import Helmet from "react-helmet"

export default () => (
	<Helmet
		htmlAttributes={{
			lang: 'ja',
		}}
		title="日影商事株式会社"
		meta={[
			{ name: 'description', content: '日影商事株式会社の公式ホームページ' }
		]}
	/>
)